import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from './store'
import Vuex from 'vuex'
import Storage from 'vue-ls'
import Toasted from 'vue-toasted'

Vue.config.productionTip = false
Vue.use(Toasted)
var options = {
  namespace: 'tss-nps-v2__',
  name: 'ls',
  storage: 'local'
}
Vue.prototype.$http = axios
Vue.prototype.$hostname = process.env.V1_HOST
Vue.use(Storage, options)
Vue.use(VueAxios, axios)
Vue.use(Vuex)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
