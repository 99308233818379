import Vue from 'vue'
import api from '../../api/api'
import router from '../../router'

const state = {
  token: '',
  status: false,
  username: '',
  isLoginButtonDisabled: false
}
const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  username: state => state.username,
  isLoginButtonDisabled: state => state.isLoginButtonDisabled
}
const actions = {
  CHECK_AUTH: (state) => {
    let token = Vue.ls.get('tss_token')
    let username = Vue.ls.get('tss_username')
    if (!token || !username) {
      return
    }
    // verify token here
    state.commit('SET_AUTH')
  },
  GET_LOGIN_AUTH: (state, payload) => {
    state.commit('SET_LOGIN_BUTTON', true)
    api.post(process.env.VUE_APP_LOGIN_URL + 'login-with-questions', payload).then((res) => {
      if (res && res.status === 200 && res.data && Object.keys(res.data).length) {
        state.commit('SET_LOGIN_AUTH', res.data)
        router.push('/feedback')
      }
    }).catch(error => {
      if (error.data) {
        Vue.toasted.show(error.data.title, {
          theme: 'primary',
          className: 'toasted-customred',
          position: 'top-right',
          duration: 5000
        })
        state.commit('SET_LOGIN_BUTTON', false)
      }
    })
  }
}
const mutations = {
  SET_AUTH: (state) => {
    state.status = true
    state.username = Vue.ls.get('tss_username')
    state.token = Vue.ls.get('tss_token')
  },
  SET_LOGIN_AUTH: (state, payload) => {
    Vue.ls.set('tss_token', payload.access_token)
    Vue.ls.set('tss_username', payload.username)
    state.status = true
  },
  LOGOUT_USER: (state) => {
    state.status = false
  },
  SET_LOGIN_BUTTON: (state, flag) => {
    state.isLoginButtonDisabled = flag
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}