import Vue from 'vue'
import api from '../../api/api'
import router from '../../router'
const state = {
  surveyQuesData: {},
  surveyOrderData: {},
  surveyOrderMsg: 'Un-Recognised Access'
}
const getters = {
  surveyQuesData: state => state.surveyQuesData,
  surveyOrderData: state => state.surveyOrderData,
  surveyOrderMsg: state => state.surveyOrderMsg
}
const actions = {
  GET_SURVEY_QUESTIONS: (state) => {
    let token = Vue.ls.get('tss_token')
    api.get(process.env.VUE_APP_URL + 'questions', { headers: { Authorization: token } }).then((res) => {
      if (res && res.status === 200 && res.data && res.data.questions) {
        state.commit('SET_SURVEY_QUESTIONSS_DATA', res.data.questions)
      }
    })
  },
  SUBMIT_SURVEY_ANSWERS: (state, payload) => {
    let token = Vue.ls.get('tss_token')
    api.post(process.env.VUE_APP_URL + 'questions', payload, { headers: { Authorization: token } }).then((res) => {
      if (res && res.status === 200) {
        if (Object.keys(state.getters.surveyOrderData).length > 0) {
          state.commit('SET_SURVEY_RESULT_MSG', 'Thanks for Submitting your Feedback')
          router.replace('/survey-result')
        } else {
          Vue.ls.remove('tss-answers')
          router.replace('/thank-you')
        }
      }
    }).catch((err) => {
      Vue.toasted.show(err && err.data && err.data.message ? err.data.message : 'Something Went wrong. Please try again', {
        theme: 'primary',
        className: 'toasted-customred',
        position: 'top-right',
        duration: 5000
      })
      console.log(err)
    })
  },
  VALIDATE_SURVEY_REQUEST: (state, payload) => {
    let token = payload && payload.token
    api.post(process.env.VUE_APP_URL + 'verify-nps-token', {order_id: payload && parseInt(payload.order_id)}, { headers: { Authorization: token } }).then((res) => {
      if (res && res.status === 200 && res.data && res.data.data) {
        Vue.ls.set('tss_token', res.data.data && res.data.data.access_token)
        res.data.data.order_id = payload.order_id
        state.commit('SET_SURVEY_ORDER_DATA', res.data.data)
      }
    }).catch((err) => {
      console.log(err)
      state.commit('SET_SURVEY_RESULT_MSG', err && err.message)
      router.replace('/survey-result')
    })
  }
}
const mutations = {
  SET_SURVEY_QUESTIONSS_DATA: (state, payload) => {
    state.surveyQuesData = payload
  },
  SET_SURVEY_ORDER_DATA: (state, payload) => {
    state.surveyOrderData = payload
  },
  SET_SURVEY_RESULT_MSG: (state, payload) => {
    state.surveyOrderMsg = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}