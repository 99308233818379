export const appMixins = {
  computed: {
    isDesktop: function () {
      const b = window.innerWidth
      if (b < 991) {
        return false
      } else {
        return true
      }
    }
  }
}