import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import feedback from './modules/feedback'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
    feedback: feedback
  },
  state: {
    loading: false
  },
  getters: {
    loading: state => state.loading
  },
  actions: {
    LOADING: (state, flag) => {
      state.commit('SET_LOADING', flag)
    }
  },
  mutations: {
    SET_LOADING: (state, flag) => {
      state.loading = flag
    }
  }
})