<template>
  <div class="row justify-content-md-center pt-5">
    <div class="loginbox col-md-5 m-2">
        <div class="login-form-wrap">
        <div class="text-center">
            <img :class="$parent.isDesktop ? 'mt-3' : ''" src="https://www.thesouledstore.com/static/img/newlogosticky.f7f01f0.png" />
            <p class="title">Login with The Souled Store</p>
        </div>
        <form class="mt20" name="forgotpassword" v-on:submit.prevent="getAuthorization()">
            <div class="tab-content">
            <div class="edit-prefix">
                <input type="email" class="form-control" placeholder="Enter Email ID" v-model="emailid" required>
                <div class="error-message">{{ }}</div>
            </div>
            <div class="pwd mt-3">     
                <input type="password" class="form-control" placeholder="Enter Password" v-model="password" maxlength="20" minlength="6" required>
                <div class="error-message">{{ }}</div>
            </div>
            <button type="submit" :disabled="isLoginButtonDisabled" class="btn btn-main mt-3 pointer btn-block text-uppercase sendlink login-btn" :class="[password.length !== 0 && 'add-opacity']">Log In
            </button>
            </div>
        </form>
        </div>
    </div>
  </div>
</template>

<script>
  var $ = require('jquery')
  window.jQuery = $
  import Vue from 'vue'
  import {mapGetters} from 'vuex'
  export default {
    props: [],
    data () {
      return {
        redirectUrl: '/',
        newTab: 'false',
        password: '',
        emailid: ''
      }
    },
    methods: {
      getAuthorization () {
        Vue.ls.set('userEmail', this.emailid)
        this.$store.dispatch('GET_LOGIN_AUTH', {email: this.emailid, password: this.password})
      }
    },
    mounted () {
      if (Vue.ls.get('userEmail')) {
        this.emailid = Vue.ls.get('userEmail')
      }
      this.$store.commit('SET_LOGIN_BUTTON', false)
    },
    computed: {
      ...mapGetters({
        isLoginButtonDisabled: 'isLoginButtonDisabled'
      })
    }
  }
</script>

<style scoped>
.login-form{
  background: #e6e7e8;
  height: 100vh;
}
.title {
  padding: 15px 0;
  font-size: 16px;
  font-weight: 700;
  color: #58595b;
}
.loginbox .tab-content {
  background: #f1f1f2;
  margin-top: 15px;
  padding: 30px;
  border: 1px #d1d3d4 solid;
}
.loginbox .form-check-label {
  padding-left: .25rem;
}
.loginbox .tab-content {
  background: #f1f1f2;
  margin-top: 15px;
  padding: 30px;
  border: 1px #d1d3d4 solid;
}
.add-opacity {
  opacity: 1;
}
@media screen and (max-width: 480px) {
  .loginbox .tab-content {
    padding: 15px;
  }
  .loginbox {
    padding: 70px 15px 40px 15px;
  }
  .loginbox .tab-content {
    padding: 15px;
  }
}
</style>
