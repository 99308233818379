import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import LoginForm from '@/components/common/LoginForm'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginForm
    },
    {
      path: '/feedback',
      name: 'feedback survey',
      component: () => import('@/components/FeedBackSurvey'),
      meta: { requiresAuth: true }
    },
    {
      path: '/order-details',
      name: 'order details',
      component: () => import('@/components/orderInfo.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/thank-you',
      name: 'thank you',
      component: () => import('@/components/common/thankYou.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/feedback/pos',
      name: 'order feedback user',
      component: () => import('@/components/FeedBackSurveyUser.vue')
    },
    {
      path: '/survey-result',
      name: 'order feedback user result',
      component: () => import('@/components/common/SurveyUserMsg.vue')
    }   
  ],
  scrollBehavior () {
    return {x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    store.dispatch('CHECK_AUTH')
    if (store.getters.authStatus) {
      next({
        path: '/feedback'
      })
    } else {
      next()
    }
  } else if (to.path === '/') {
    store.dispatch('CHECK_AUTH')
    if (store.getters.authStatus) {
      next({
        path: '/feedback'
      })
    } else {
      next({
        path: '/login'
      })
    }
  } else if (from.path === '/thank-you' && to.path === '/order-details') {
    next({
      path: '/feedback'
    })
  } else if (from.path === '/feedback' && to.path === '/thank-you') {
    next({
      path: '/feedback'
    })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch('CHECK_AUTH')
    if (!store.getters.authStatus) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router