import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'

const axiosObj = axios.create({
  baseURL: process.env.VUE_APP_URL, // 'http://localhost:8000/api/v2/',
  headers: {
    // 'mactoken': Vue.ls.get('tss_mac_token'),
    'Content-Type': 'application/json'
  }
})
axiosObj.interceptors.request.use((config) => {
  store.dispatch('LOADING', true)
  return config
})
axiosObj.interceptors.response.use((response) => {
  store.dispatch('LOADING', false)
  return response
}, function (error) {
  store.dispatch('LOADING', false)
  return Promise.reject(error.response)
})

function errorResponseHandler (error) {
  // check for errorHandle config
if (error.status === 401 && error.request.responseURL !== process.env.VUE_APP_LOGIN_URL + 'login-with-questions') {
  Vue.toasted.clear()
  Vue.toasted.show(error.data.title, {
    theme: 'primary',
    className: 'toasted-customred',
    position: 'top-right',
    duration: 5000
  })
  Vue.ls.remove('tss_token')
  Vue.ls.remove('tss_username')
  store.commit('LOGOUT_USER')
  router.push('/login')
}
return Promise.reject(error)
}

// apply interceptor on response
axiosObj.interceptors.response.use(
 response => response,
 errorResponseHandler
)
export default axiosObj