<template>
  <div id="app">
    <div>
      <AppNav v-if="authStatus"/>
      <transition name="fade">
        <div class='tss-body-wrapper' :style="authStatus ? 'padding-top: 70px;' : ''">
          <router-view class="viewContainer"></router-view>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import './assets/css/style.css'
import './assets/css/bootstrap.css'
import {appMixins} from './assets/js/appMixins'
import AppNav from '@/components/common/AppNav.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'App',
  mixins: [appMixins],
  components: {
    AppNav
  },
  computed: {
    ...mapGetters({
      authStatus: 'authStatus'
    })
  }
}
</script>

<style lang="css" scoped>
.tss-body-wrapper{
  max-width: 1500px;
  margin: auto;
}
</style>
