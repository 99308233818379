<template>
  <div class="AppNav">
    <div class="col-12 d-flex header" :class="!$parent.isDesktop ? 'p-0' : ''">
        <div class="col-3" :class="!$parent.isDesktop ? 'p-0' : ''">
          <img class="imgwrap" src="https://www.thesouledstore.com/static/img/newlogosticky.f7f01f0.png" />
        </div>
        <div class="col-9 text-right" :class="!$parent.isDesktop ? 'p-0 ml-2' : ''">
          <p class="m-0 login-tile">Welcome, {{username}} </p>
          <button class="card-removeButton" @click="logoutHandler">Logout</button>
        </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
export default {
  methods: {
    logoutHandler () {
      Vue.ls.remove('tss_token')
      Vue.ls.remove('tss_username')
      this.$store.commit('LOGOUT_USER')
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapGetters({
      username: 'username'
    })
  },
  mounted () {
    Vue.ls.remove('userEmail')
  }
}
</script>
<style scoped>
.AppNav {
  padding: 10px 16px;
  background-color: #ed2d2f;
  color: #f1f1f1;
  position: fixed;
  top: 0;
  width: 100%;
  height:70px;
  z-index: 101!important;
}
.login-tile {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.imgwrap{
  object-fit: contain;
  max-height: 50px;
}
.card-removeButton {
  padding: 6px 0px;
  text-align: center;
  color: #fff !important;
  text-decoration: underline;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background: transparent;
  border: none;
}
.card-removeButton:active{
  outline: none
}
.card-removeButton:focus{
  outline: none
}
</style>